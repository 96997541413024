export default {
  inventoryByStatus: () => {
    return {
      title: "Lot inventory by status",
      amount: 0,
      link: "/inventory/details",
      option: {
        chartType: "bar",
        colorBy: true,
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "LOT COUNTS",
          nameGap: 40,
        },
        series: [],
        color: [
          "#1EC48C",
          "#FECE5B",
          "#3F3356",
          "#FD6C83",
          "#9693E8",
          "#3395c6",
        ],
      },
    };
  },

  inventoryByProductType: () => {
    return {
      title: "Lot inventory by product type",
      amount: 0,
      link: "/inventory/details",
      itemByColor: {
        Available: "#1EC48C",
        Sold: "#FD6C83",
        Hold: "#FECE5B",
        "Sold Conditional": "#9693E8",
        "Standing Inventory": "#3395c6",
        "Not Released": "#3F3356",
      },
      option: {
        chartType: "bar",
        chartColumn: "stack",
        xAxis: {
          data: [],
          sortData: true,
        },
        yAxis: {
          name: "LOT COUNTS",
          nameGap: 40,
        },
        series: [],
      },
    };
  },

  inventoryByModelElevation: () => {
    return {
      title: "Standing inventory value by model/elevation",
      amount: 0,
      amountPrefix: "$",
      link: "/inventory/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
          sortData: true,
        },
        yAxis: {
          name: "SUM OF MODEL INVENTORY VALUE",
          nameGap: 100,
          axisLabel: {
            formatter: "${value}",
          },
        },
        series: [],
      },
    };
  },
};
