var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 w-full dF fC f1 pb-4 hide-scrollbar",staticStyle:{"overflow-y":"scroll"}},[_c('div',[_c('a-tabs',{attrs:{"size":"large","default-active-key":1},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('div',{staticClass:"dF aC",staticStyle:{"gap":"20px"},attrs:{"slot":"tabBarExtraContent"},slot:"tabBarExtraContent"},[(_vm.activeTab === 1 && _vm.corporateInstance)?_c('div',{staticClass:"dF aC",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Projects:")]),_c('SelectProjects',{staticClass:"ml-2"})],1):_vm._e(),(_vm.activeTab === 1)?_c('div',{staticClass:"dF aC",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Print:")]),_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.downloadPDF.apply(null, arguments)}}},[_vm._v(" Charts ")]),_c('PDFOrientation',{staticClass:"ml-2"})],1):_vm._e(),_c('div',{staticClass:"dF aC",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Download:")]),_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.getCSV.apply(null, arguments)}}},[_vm._v("CSV")])]),_c('DatePicker',{attrs:{"disabled-compare-date":true},on:{"current-times-update":function($event){return _vm.fetchChartData($event, 'currentData')}}}),(_vm.activeTab === 2)?_c('div',{staticClass:"dF aC text-primary"},[_c('a-select',{staticClass:"text-primary",staticStyle:{"width":"150px"},attrs:{"placeholder":"Select Date","showArrow":true,"dropdownMenuStyle":{
							'text-align': 'center',
							'overflow-y': 'hidden',
							'max-height': 'max-content',
						}},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}},[_c('a-icon',{staticClass:"text-primary",attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.typeList),function(t){return _c('a-select-option',{key:t,staticClass:"text-primary",attrs:{"value":t}},[_vm._v(" "+_vm._s(t)+" ")])})],2)],1):_vm._e()],1),_c('a-tab-pane',{key:1,staticStyle:{"gap":"20px"},attrs:{"tab":"Overview"}},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":false,"preview-modal":false,"pdf-content-width":"100%","manual-pagination":true,"html-to-pdf-options":{
						margin: _vm.pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
						image: { type: 'jpeg', quality: 2 },
						enableLinks: true,
						html2canvas: { scale: 1, useCORS: true },
						jsPDF: {
							orientation: _vm.pdfOrientation
						}
					}},on:{"beforeDownload":function($event){return _vm.beforeDownload($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[(!_vm.error)?_c('div',{staticClass:"report-grid"},[_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.inventoryByStatus,"loading":_vm.loading}}),_c('ChartCard',{staticClass:"html2pdf__page-break",attrs:{"details":_vm.inventoryByProductType,"loading":_vm.loading}})],1):_vm._e(),(!_vm.error)?_c('div',{staticClass:"report-grid report-grid-1"},[_c('ChartCard',{attrs:{"details":_vm.inventoryByModelElevation,"loading":_vm.loading}})],1):_c('div',{staticClass:"mt-10",staticStyle:{"text-align":"center"}},[_c('h4',[_vm._v("Something went wrong. Please Try again!")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.fetchChartData}},[_vm._v("Retry")])],1)])])],1),_c('a-tab-pane',{key:2,attrs:{"tab":"Pricing History"}},[_c('a-card',{staticClass:"table-card"},[_c('a-table',{staticClass:"tableStyle",attrs:{"columns":_vm.columns,"data-source":_vm.dataRecords,"row-key":(record) => record.id},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"conditionPremiums",fn:function(conditionPremiums){return _vm._l((conditionPremiums),function(cp,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(cp)+" ")])})}},{key:"dates",fn:function(dates){return _vm._l((dates),function(date,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(_vm.formatDate(date))+" ")])})}},{key:"percentageChanges",fn:function(percentageChanges){return _vm._l((percentageChanges),function(p,i){return _c('span',{key:i},[_c('div',{class:p > 0
									? 'text-success'
									: p < 0
										? 'text-danger'
										: 'text-gray'},[_vm._v(" "+_vm._s(p)+" % ")])])})}},{key:"priceChanges",fn:function(priceChanges){return _vm._l((priceChanges),function(p,i){return _c('span',{key:i},[_c('div',{class:p > 0
									? 'text-success'
									: p < 0
										? 'text-danger'
										: 'text-gray'},[_vm._v(" $"+_vm._s(p)+" ")])])})}},{key:"totalPriceChanges",fn:function(totalPriceChanges){return _vm._l((totalPriceChanges),function(tp,i){return _c('div',{key:i},[_vm._v(" $"+_vm._s(tp)+" ")])})}}])})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }