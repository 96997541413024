<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<a-tabs size="large" :default-active-key="1" v-model="activeTab">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 20px">
					<div v-if="activeTab === 1 && corporateInstance" class="dF aC" style="gap: 5px">
						<span>Projects:</span>
						<SelectProjects class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px" v-if="activeTab === 1">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>
					<DatePicker @current-times-update="fetchChartData($event, 'currentData')"
						:disabled-compare-date="true" />
					<div v-if="activeTab === 2" class="dF aC text-primary">
						<a-select v-model="selectedType" style="width: 150px" placeholder="Select Date" class="text-primary"
							:showArrow="true" :dropdownMenuStyle="{
								'text-align': 'center',
								'overflow-y': 'hidden',
								'max-height': 'max-content',
							}">
							<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />

							<a-select-option v-for="t in typeList" :key="t" :value="t" class="text-primary">
								{{ t }}
							</a-select-option>
						</a-select>
					</div>
				</div>
				<a-tab-pane :key="1" tab="Overview" style="gap: 20px">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="!error" class="report-grid">
								<ChartCard class="html2pdf__page-break" :details="inventoryByStatus" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="inventoryByProductType"
									:loading="loading" />
							</div>
							<div v-if="!error" class="report-grid report-grid-1">
								<ChartCard :details="inventoryByModelElevation" :loading="loading" />
							</div>
							<div v-else class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="fetchChartData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
				<a-tab-pane :key="2" tab="Pricing History">
					<a-card class="table-card">
						<a-table class="tableStyle" :columns="columns" :data-source="dataRecords"
							:row-key="(record) => record.id" @change="handleTableChange">
							<template #conditionPremiums="conditionPremiums">
								<div v-for="(cp, i) in conditionPremiums" :key="i">
									{{ cp }}
								</div>
							</template>
							<template #dates="dates">
								<div v-for="(date, i) in dates" :key="i">
									{{ formatDate(date) }}
								</div>
							</template>
							<template #percentageChanges="percentageChanges">
								<span v-for="(p, i) in percentageChanges" :key="i">
									<div :class="p > 0
										? 'text-success'
										: p < 0
											? 'text-danger'
											: 'text-gray'
										">
										{{ p }} %
									</div>
								</span>
							</template>

							<template #priceChanges="priceChanges">
								<span v-for="(p, i) in priceChanges" :key="i">
									<div :class="p > 0
										? 'text-success'
										: p < 0
											? 'text-danger'
											: 'text-gray'
										">
										${{ p }}
									</div>
								</span>
							</template>
							<template #totalPriceChanges="totalPriceChanges">
								<div v-for="(tp, i) in totalPriceChanges" :key="i">
									${{ tp }}
								</div>
							</template>
						</a-table>
					</a-card>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import chartData from "./chartData";
import DatePicker from "@/components/charts/datePicker.vue";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import SelectProjects from "@/components/charts/selectProjects.vue";
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import { formatDate } from 'bh-mod'

export default {
	components: {
		ChartCard,
		DatePicker,
		VueHtml2pdf,
		PDFOrientation,
		SelectProjects
	},
	data: () => ({
		loading: false,
		error: false,
		activeTab: 1,
		dataRecords: [],
		sortedInfo: {},
		selectedType: "",
		inventoryReport: {
			currentData: {},
			compareData: {},
		},
	}),
	computed: {
		...mapState([
			"currentStartDate",
			"currentEndDate",
			"compareStartDate",
			"compareEndDate",
			"records",
			"instance",
		]),

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate')
		},

		chartTitles() {
			if (this.productType === "lowrise") {
				return [
					'Lot inventory by status',
					'Lot inventory by product type',
					'Standing inventory value by model/elevation',
				]
			} else {
				return [
					'Inventory by unit status',
					'Inventory by unit type',
					'Inventory value by unit type'
				]
			}
		},

		typeList() {
			if (this.productType === "lowrise") {
				return ["By Lot", "By Model/Elevation"];
			} else {
				return ["By Unit", "By Premium", "By Add On"];
			}
		},

		productType() {
			return this.instance.productType;
		},

		inventoryByStatus() {
			const details = chartData.inventoryByStatus();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
				delete details.option.colorBy
				delete details.option.color
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails("lotInventoryByStatus"),
				};
			}
			details.option.yAxis.name = "UNIT COUNTS"
			if (details.option.color) {
				details.option.color = details.option.color.filter(c => c !== '#3395c6')
			}
			return {
				...details,
				title: "Inventory by unit status",
				chartDetails: this.getChartDetails("inventoryByUnitStatus"),
			};
		},

		inventoryByProductType() {
			const details = chartData.inventoryByProductType();
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails(
						"lotInventoryByProductType"
					),
				};
			}
			details.option.yAxis.name = "UNIT COUNTS"
			if (details.itemByColor && details.itemByColor["Standing Inventory"]) {
				delete details.itemByColor["Standing Inventory"];
			}
			return {
				...details,
				title: "Inventory by unit type",
				chartDetails: this.getChartDetails("inventoryByUnitType"),
			};
		},

		inventoryByModelElevation() {
			const details = chartData.inventoryByModelElevation();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails("inventoryValueByModel"),
				};
			}
			details.option.yAxis.name = "SUM OF UNIT INVENTORY VALUE"
			return {
				...details,
				title: "Inventory value by unit type",
				chartDetails: this.getChartDetails("inventoryValueByUnit"),
			};
		},

		columns() {
			return this.getColumns();
		},

		filename() {
			return `Inventory Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},
	},

	created() {
		this.selectedType =
			this.productType === "lowrise" ? "By Lot" : "By Unit";
	},

	methods: {
		...mapMutations(["setCurrentDates", "setCompareDates", "setRecords"]),
		formatDate,

		getChartDetails(chartType) {
			const chartDetails = [];
			if (this.inventoryReport.currentData[chartType]) {
				chartDetails.push({
					startDate: this.currentStartDate,
					endDate: this.currentEndDate,
					data: this.inventoryReport.currentData[chartType],
				});
			}

			if (this.inventoryReport.compareData[chartType]) {
				chartDetails.push({
					startDate: this.compareStartDate,
					endDate: this.compareEndDate,
					data: this.inventoryReport.compareData[chartType],
				});
			}
			return chartDetails;
		},

		fetchChartData(
			{
				startDate = this.currentStartDate,
				endDate = this.currentEndDate,
			},
			dateType = "currentData"
		) {
			this.error = false;
			this.loading = true;

			if (dateType === "currentData") {
				this.setCurrentDates({ startDate, endDate });
			} else {
				this.setCompareDates({ startDate, endDate });
			}

			if (!startDate && !endDate) {
				this.inventoryReport[dateType] = {};
				this.loading = false;
				this.$forceUpdate();
				return;
			}

			this.$api
				.post(`/daily-reports/${this.$store.state.instance.id}/graph`, {
					type: "inventory",
					start: startDate,
					end: endDate,
					pType: this.productType,
				})
				.then(({ data }) => {
					this.setRecords({
						records: data.records,
						type: "inventory",
					});
					delete data.records;
					this.inventoryReport[dateType] = data;
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
					this.error = true;
					this.loading = false;
				});
		},

		getColumns() {
			let { sortedInfo } = this;
			sortedInfo = sortedInfo || {};
			const columns = [];
			if (this.selectedType === "By Lot") {
				this.dataRecords = this.records.inventory.priceHistory.lots;
				columns.push(
					...[
						{
							title: "Lot #",
							dataIndex: "lot",
							key: "lot",
							sorter: (a, b) => parseInt(a.lot) - parseInt(b.lot),
							sortOrder:
								sortedInfo.columnKey === "lot" &&
								sortedInfo.order,
						},
						{
							title: "Condition/Premium",
							dataIndex: "conditionPremiums",
							key: "conditionPremiums",
							scopedSlots: { customRender: "conditionPremiums" },
						},
					]
				);
			} else if (this.selectedType === "By Model/Elevation") {
				this.dataRecords =
					this.records.inventory.priceHistory.elevation;
				columns.push(
					...[
						{
							title: "Model/Elevation",
							dataIndex: "modelElevation",
							key: "modelElevation",
						},
						{
							title: "Model Type",
							dataIndex: "modelType",
							key: "modelType",
						},
						{
							title: "Frontage",
							dataIndex: "frontage",
							key: "frontage",
						},
					]
				);
			} else if (this.selectedType === "By Unit") {
				this.dataRecords = this.records.inventory.priceHistory.units;
				columns.push(
					...[
						{
							title: "Unit #",
							dataIndex: "unit",
							key: "unit",
							sorter: (a, b) => a.unit - b.unit,
							sortOrder:
								sortedInfo.columnKey === "unit" &&
								sortedInfo.order,
						},
						{
							title: "Unit Name",
							dataIndex: "unitName",
							key: "unitName",
							sorter: (a, b) =>
								a.unitName < b.unitName
									? -1
									: a.unitName > b.unitName
										? 1
										: 0,
							sortOrder:
								sortedInfo.columnKey === "unitName" &&
								sortedInfo.order,
						},
						{
							title: "Unit Type",
							dataIndex: "unitType",
							key: "unitType",
							sorter: (a, b) =>
								a.unitType < b.unitType
									? -1
									: a.unitType > b.unitType
										? 1
										: 0,
							sortOrder:
								sortedInfo.columnKey === "unitType" &&
								sortedInfo.order,
						},
						{
							title: "Unit Price",
							dataIndex: "unitPrice",
							key: "unitPrice",
							scopedSlots: { customRender: "unitPrice" },
						},
					]
				);
			} else if (this.selectedType === "By Premium") {
				this.dataRecords = this.records.inventory.priceHistory.premiums;
				columns.push(
					...[
						{
							title: "Unit #",
							dataIndex: "unit",
							key: "unit",
							sorter: (a, b) => a.unit - b.unit,
							sortOrder:
								sortedInfo.columnKey === "unit" &&
								sortedInfo.order,
						},
						{
							title: "Unit Premium",
							dataIndex: "unitPremium",
							key: "unitPremium",
							scopedSlots: { customRender: "unitPremium" },
						},
					]
				);
			} else if (this.selectedType === "By Add On") {
				this.dataRecords = this.records.inventory.priceHistory.addOns;
				columns.push(
					...[
						{
							title: "Unit #",
							dataIndex: "unit",
							key: "unit",
							sorter: (a, b) => a.unit - b.unit,
							sortOrder:
								sortedInfo.columnKey === "unit" &&
								sortedInfo.order,
						},
						{
							title: "Unit Add On",
							dataIndex: "unitAddOn",
							key: "unitAddOn",
							scopedSlots: { customRender: "unitAddOn" },
						},
					]
				);
			}

			columns.push(
				...[
					{
						title: "Date",
						dataIndex: "dates",
						key: "dates",
						scopedSlots: { customRender: "dates" },
					},
					{
						title: "Percentage",
						dataIndex: "percentageChanges",
						key: "percentageChanges",
						scopedSlots: { customRender: "percentageChanges" },
					},
					{
						title: "Price change",
						dataIndex: "priceChanges",
						key: "priceChanges",
						scopedSlots: { customRender: "priceChanges" },
					},
					{
						title: "Total Price",
						dataIndex: "totalPriceChanges",
						key: "totalPriceChanges",
						scopedSlots: { customRender: "totalPriceChanges" },
					},
				]
			);
			return columns;
		},

		handleTableChange(pagination, filters, sorter) {
			this.sortedInfo = sorter;
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);

						pdf.setFontSize(16);

						const title = this.chartTitles[i - 1] || ''
						pdf.text(
							title,
							(pdf.internal.pageSize.getWidth() * 0.44) - title.length,
							(this.pdfOrientation === 'portrait' ? 25 : 10)
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		getCSV() {
			if (this.productType === "lowrise") {
				if (this.activeTab === 1) {
					this.createCSV(this.records.inventory.lots.map((l) => {
						return {
							...l,
							closingDate: l.closingDate && formatDate(l.closingDate) || "",
						}
					}));
				} else {
					const priceHistoryType =
						this.selectedType === "By Lot" ? "lots" : "elevation";
					this.createCSV(
						this.records.inventory.priceHistory[priceHistoryType].map((ph) => {
							let dates = (ph.dates || []).map(d => d && formatDate(d) || '')
							return {
								...ph,
								dates: dates || "",
							}
						}),
						`Inventory Price History by ${priceHistoryType}(${moment(
							this.currentStartDate
						).format("ll")} - ${moment(this.currentEndDate).format(
							"ll"
						)})`
					);
				}
			} else {
				if (this.activeTab === 1) {
					this.createCSV(this.records.inventory.units);
				} else {
					const priceHistoryType =
						this.selectedType === "By Unit"
							? "units"
							: this.selectedType === "By Premium"
								? "premiums"
								: "addOns";
					this.createCSV(
						this.records.inventory.priceHistory[priceHistoryType].map((ph) => {
							let dates = (ph.dates || []).map(d => d && formatDate(d) || '')
							return {
								...ph,
								dates: dates || "",
							}
						}),
						`Inventory Price History by ${priceHistoryType}(${moment(
							this.currentStartDate
						).format("ll")} - ${moment(this.currentEndDate).format(
							"ll"
						)})`
					);
				}
			}
		},

		createCSV(records, filename = this.filename) {
			if (records.length) {
				let csv = Object.keys(records[0]).join(",");
				csv += "\n";
				records.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (!r) {
								return "";
							}
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							if (typeof r === "object") {
								if (r.premiums && r.premiums.length) {
									return r.premiums
										.map((rp) => {
											return rp.replaceAll(",", "");
										})
										.join(" & ");
								}
								if (r.addOns && r.addOns.length) {
									return r.addOns
										.map((rp) => {
											return rp.replaceAll(",", "");
										})
										.join(" & ");
								}
								if (r.length) {
									return r
										.map((rp) => {
											if (typeof rp === "string") {
												return rp
													.replaceAll(", ", " & ")
													.replaceAll(",", "");
											}
											return rp;
										})
										.join(" => ");
								}
								return "";
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.report-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;

	@media screen and (max-width: 1279px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.report-grid-1 {
	padding-top: 20px;
	grid-template-columns: auto;

	@media screen and (min-width: 768px) {
		grid-template-columns: auto;
	}
}

.ant-select-selection__placeholder {
	color: var(--primary);
}
</style>
